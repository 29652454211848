import client from '@config/client'
import API_URL from '@config/services'

export const patchReport = (id, payload) => {
  return client
    .patch(API_URL.REPORTS.PATCH_REPORT(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteReport = id => {
  return client
    .delete(API_URL.GOALS.DELETE_REPORT(id))
    .then(response => response)
    .catch(error => error.response)
}

export const postReportsList = (orgPk, projectId, payload) =>
  client
    .post(API_URL.REPORTS.POST_REPORTS_LIST(orgPk, projectId), payload)
    .then(response => response)
    .catch(error => error.response)

export const postReportPreview = (orgPk, proposalId) =>
  client
    .post(
      API_URL.REPORTS.POST_REPORTS_PREVIEW(orgPk, proposalId),
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => response)
    .catch(error => error.response)

// export const postReportPreviewByType = (orgPk, type, payload) =>
//   client
//     .post(API_URL.REPORTS.POST_REPORTS_PREVIEW_BY_TYPE(orgPk, type), payload, {
//       responseType: 'arraybuffer'
//     })
//     .then(response => response)
//     .catch(error => error.response)

export const postReportPreviewByQuery = payload =>
  client
    .post(API_URL.REPORTS.POST_REPORTS_PREVIEW_BY_QUERY, payload, {
      responseType: 'arraybuffer'
    })
    .then(response => response)
    .catch(error => error.response)

export const postGenerateReport = (orgPk, payload) =>
  client
    .post(API_URL.REPORTS.POST_GENERATE_REPORT(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)

export const patchReportProposal = (proposalId, payload) =>
  client
    .patch(API_URL.REPORTS.GET_REPORTS(proposalId), payload)
    .then(response => response)
    .catch(error => error.response)

export const deleteReportProposal = proposalId =>
  client
    .delete(API_URL.REPORTS.GET_REPORTS(proposalId))
    .then(response => response)
    .catch(error => error.response)

/* Templates */
export const postReportTemplates = (teamId, payload) =>
  client
    .post(API_URL.REPORTS.POST_REPORTS_TEMPLATES_LIST(teamId), payload)
    .then(response => response)
    .catch(error => error.response)

export const postReportTemplatesDuplicate = id =>
  client
    .post(API_URL.REPORTS.POST_REPORTS_TEMPLATES_DUPLICATE(id))
    .then(response => response)
    .catch(error => error.response)

export const postReportPreviewTemplate = (orgPk, templateId) =>
  client
    .post(
      API_URL.REPORTS.POST_REPORTS_PREVIEW_TEMPLATE(orgPk, templateId),
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => response)
    .catch(error => error.response)

export const patchReportTemplate = (id, payload) =>
  client
    .patch(API_URL.REPORTS.PATCH_REPORT_TEMPLATE(id), payload)
    .then(response => response)
    .catch(error => error.response)

export const deleteReportTemplate = id =>
  client
    .delete(API_URL.REPORTS.DELETE_REPORT_TEMPLATE(id))
    .then(response => response)
    .catch(error => error.response)

export const downloadXls = (url, org, name) => {
  return client
    .get(url + '&org=' + org, {
      responseType: 'arraybuffer'
    })
    .then(response => {
      if (response.data && response.data.byteLength) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click()
      } else {
        return 'no_data'
      }
    })
    .catch(error => error.response)
}

export const getExplorer = async payload => {
  try {
    const response = await client.get(API_URL.REPORTS.EXPLORER, {
      params: payload
    })
    return response
  } catch (error) {
    return error.response
  }
}
