import Link from 'next/link'
import { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  Alert,
  Box,
  MenuItem,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Avatar,
  Stack,
  IconButton,
  Tooltip
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import CommonModal from '@components/common/Modal/CommonModal'
import ScheduleModal from './ScheduleModal'
import routes from '@config/routes'
import API_URL from '@config/services'
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import TextFieldItem from '@components/common/Form/TextFieldItem'
import Select from '@components/common/Select'
import { sendEmailTest, updateInvoice } from '@api/invoices'
import queryString from '@utils/queryString'
import { mutate } from 'swr'
import useTableStyles from '@styles/table/table'
import { Mail, OpenInNew, Settings } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import DeleteModal from '@components/common/DeleteModal'
import unformatDate from '@utils/unformatDate'
import { format } from '@utils/date-fns_functions'
import NotifyCoContractors from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/External/ContentTabs/Summary/NotifyCoContractors'
import { responseHandler } from '@utils/responseHandler'
import AutocompleteItem from '@components/common/Autocomplete'

const Email = ({
  invoiceData,
  invoicesURL,
  fetchInvoiceUrl,
  contractorsEnabled,
  invoicesCountsURL
}) => {
  const { organizationTeam, orgSettings } = useAuth()
  const { dictionary, lang } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classesTable = useTableStyles()

  const [isNotify, setIsNotify] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isschedule, setIsschedule] = useState(false)
  const [isCancelScheduling, setIsCancelScheduling] = useState(false)
  const [isConfirmSend, setIsConfirmSend] = useState(false)

  const { data: emails } = useFetchData(
    API_URL.EMAILS.GET_EMAILS_LIST_PK +
      '?' +
      queryString({ type: 'invoice', team: organizationTeam })
  )

  const contractorsURL = invoiceData?.project
    ? API_URL.PROJECT.GET_DELIVERABLES_CONTRACTORS(invoiceData?.project) +
      '?' +
      queryString({ invoice: invoiceData?.pk })
    : null
  const { data: contractors } = useFetchData(contractorsURL)

  const schema = yup.object().shape({
    invoice_email_to: yup.string(),
    invoice_email_cc: yup.string(),
    invoice_email_bcc: yup.string(),

    invoice_email_template: yup.string(),
    reply_to: yup.string(),
    reply_to_email: yup.string(),
    from: yup.string(),
    invoice_email_subject: yup.string(),
    invoice_email_body: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      invoice_email_to: invoiceData.invoice_email_to || '',
      // invoiceData.invoice_email_to || project?.invoice_email_to || '',
      invoice_email_cc: invoiceData.invoice_email_cc || '',
      // invoiceData.invoice_email_cc || project?.invoice_email_cc || '',
      invoice_email_bcc: invoiceData.invoice_email_bcc || '',
      // invoiceData.invoice_email_bcc || project?.invoice_email_bcc || '',

      invoice_email_template: invoiceData.invoice_email_template || '',
      // invoiceData.invoice_email_template ||
      // project?.invoice_email_template ||
      // '',
      reply_to: '',
      reply_to_email: '',
      from: '',
      invoice_email_subject: invoiceData.invoice_email_subject || '',
      invoice_email_body: invoiceData.invoice_email_body || ''
    }
  })

  const emailTemplate = watch(
    'invoice_email_template',
    invoiceData.invoice_email_template || ''
  )

  const emailSubject = watch(
    'invoice_email_subject',
    invoiceData.invoice_email_subject || ''
  )

  const emailBody = watch(
    'invoice_email_body',
    invoiceData.invoice_email_body || ''
  )

  useEffect(() => {
    const currentEmailTemplate = emails?.results.find(
      e => e.id === +emailTemplate
    )

    if (currentEmailTemplate) {
      setValue('reply_to', currentEmailTemplate.name_from || '')
      setValue('reply_to_email', currentEmailTemplate.email_from || '')
      setValue(
        'invoice_email_subject',
        currentEmailTemplate.email_subject || ''
      )

      setValue('invoice_email_body', currentEmailTemplate.email_body || '')
    }
  }, [emailTemplate])

  const handleSendTest = async () => {
    const payload = {
      send_email: true,
      test_email: true
    }
    const response = await sendEmailTest(invoiceData.pk, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        await mutate(fetchInvoiceUrl)
      },
      dictionary,
      msgSuccess: dictionary.email_template_send_test,
      snackbar: enqueueSnackbar
    })
  }

  const handleSend = async () => {
    setLoading(true)
    const payload = { send_email: true }
    const response = await sendEmailTest(invoiceData.pk, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(invoicesURL)
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesCountsURL)
      },
      dictionary,
      msgSuccess: dictionary.email_template_send_test,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setIsConfirmSend(false)
  }

  const onSubmit = async values => {
    setLoading(true)
    const payload = {
      invoice_email_to: values.invoice_email_to || '',
      invoice_email_cc: values.invoice_email_cc || '',
      invoice_email_bcc: values.invoice_email_bcc || '',
      invoice_email_template: values.invoice_email_template || ''
    }
    const response = await updateInvoice(invoiceData.pk, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
  }

  const cancelScheduling = async payload => {
    setLoading(true)
    const response = await updateInvoice(invoiceData.pk, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchInvoiceUrl)
        await mutate(invoicesURL)
        await mutate(invoicesCountsURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setIsCancelScheduling(false)
  }

  const canSend =
    invoiceData?.can_send_email &&
    invoiceData?.status !== 'not_valid' &&
    orgSettings.invoice_sending_enabled

  return (
    <>
      <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={4} p={3} sx={{ height: 'fit-content' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  data-testid="Typography-65d59e8d-653c-4be0-a1cf-414424351980"
                >
                  {dictionary.client_email_info}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.light"
                  data-testid="Typography-c7e819ca-7ced-46b1-ba34-a9c0a2b97d0f"
                >
                  {dictionary.custom_information}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {/* Invoice Email To */}
                <AutocompleteItem
                  name="invoice_email_to"
                  label={dictionary.send_to_email}
                  control={control}
                  defaultValue={invoiceData.invoice_email_to || ''}
                  elements={{
                    items: [
                      invoiceData.client_email
                        ? {
                            display: invoiceData.client_email || '',
                            id: invoiceData.client_email || ''
                          }
                        : null,
                      invoiceData.invoice_email_to
                        ? {
                            display: invoiceData.invoice_email_to || '',
                            id: invoiceData.invoice_email_to || ''
                          }
                        : null
                    ].filter(o => o),
                    id: 'id',
                    display: 'display'
                  }}
                  freeSolo
                />
              </Grid>
              <Grid item xs={12}>
                {/* Invoice Email CC */}
                <Controller
                  name="invoice_email_cc"
                  control={control}
                  render={props => (
                    <TextFieldItem
                      label={dictionary.cc.toUpperCase()}
                      errorMessage={errors.invoice_email_cc?.message}
                      {...props}
                      data_testid="TextFieldItem-be29236e-63d2-41b7-b5c4-e377645bf89f"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {/* Invoice Email BCC */}
                <Controller
                  name="invoice_email_bcc"
                  control={control}
                  render={props => (
                    <TextFieldItem
                      label={dictionary.bcc.toUpperCase()}
                      errorMessage={errors.invoice_email_bcc?.message}
                      {...props}
                      data_testid="TextFieldItem-62c4973f-aedf-4848-90dd-9954119c8877"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  color="text.secondary"
                  data-testid="Typography-dcbe39f0-2ab3-4b82-961f-d382e3a51d48"
                >
                  {dictionary.invoice_email_info}
                </Typography>
              </Grid>
              {!orgSettings.invoice_sending_enabled && (
                <Grid item xs={12}>
                  <Link href={routes.account.settings.invoicing.emails}>
                    <a>
                      <Alert severity="info">
                        {dictionary.enable_invoice_emailing_description}
                      </Alert>
                    </a>
                  </Link>
                </Grid>
              )}

              <Grid item xs={6}>
                {/* Invoice Email Template */}
                <Select
                  fullWidth
                  name="invoice_email_template"
                  label={dictionary.email_template}
                  control={control}
                  // disabled={!orgSettings.invoice_sending_enabled}
                  data_testid="Select-c96e3320-0f0d-45c7-a8cd-fd7be50a21cf"
                >
                  <MenuItem
                    value={''}
                    data-testid="MenuItem-94b2e86a-cfce-417b-84c1-1c9d246c6502"
                  >
                    {'----------'}
                  </MenuItem>
                  {emails &&
                    emails.results.map(({ id, name }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        data-testid="MenuItem-d90fa8a9-6b9c-4745-9b6e-139e331fb787"
                      >
                        {name || dictionary.unnamed_template}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Link href={routes.account.settings.invoicing.emails} passHref>
                  <a target="_blank" rel="noopener noreferrer">
                    {/* <IconButton>
                             <Settings />
                             </IconButton> */}
                    <Button
                      disabled={loading}
                      variant="outlined"
                      fullWidth
                      sx={{ margin: 'auto' }}
                      data-testid="Button-9279a846-f88d-45d8-abc3-3910f2abae18"
                    >
                      <Settings fontSize="small" />
                    </Button>
                  </a>
                </Link>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  {/* Reply To */}
                  <Controller
                    name="reply_to"
                    disabled={true}
                    control={control}
                    render={props => (
                      <TextFieldItem
                        // disabled
                        label={dictionary.reply_to}
                        errorMessage={errors.reply_to?.message}
                        {...props}
                        data_testid="TextFieldItem-a740b66b-f11d-47fb-986d-16a806f0bec1"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Reply To Email */}
                  <Controller
                    name="reply_to_email"
                    disabled={true}
                    control={control}
                    render={props => (
                      <TextFieldItem
                        // disabled
                        label={dictionary.reply_to_email}
                        errorMessage={errors.reply_to_email?.message}
                        {...props}
                        data_testid="TextFieldItem-0b23df6f-7f59-4e82-8091-bc8fc6436fba"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                       <Controller
                        name="from"
                        control={control}
                        render={props => (
                          <TextFieldItem
                            disabled
                            label={dictionary.from}
                            errorMessage={errors.from?.message}
                            {...props}
                          />
                        )}
                       />
                       </Grid>
                       <Grid item xs={12}>
                       <Controller
                        name="invoice_email_subject"
                        control={control}
                        render={props => (
                          <TextFieldItem
                            disabled
                            label={dictionary.subject}
                            errorMessage={errors.invoice_email_subject?.message}
                            {...props}
                          />
                        )}
                       />
                       </Grid>
                       <Grid item xs={12}>
                       <Controller
                        name="invoice_email_body"
                        control={control}
                        render={props => (
                          <TextFieldItem
                            multiline
                            minRows={3}
                            label={dictionary.body}
                            errorMessage={errors.invoice_email_body?.message}
                            {...props}
                          />
                        )}
                       />
                       </Grid> */}
              <Grid
                item
                xs={12}
                align="right"
                justifyContent="space-between"
                display="flex"
              >
                {contractorsEnabled && (
                  <Button
                    variant="outlined"
                    onClick={() => setIsNotify(true)}
                    sx={{ marginRight: 1 }}
                    data-testid="Button-6aa3346e-1623-4e6b-8957-e8cc1caffcd4"
                  >
                    {dictionary.email_cocontratcors}
                  </Button>
                )}

                {/* {orgSettings.invoice_sending_enabled && ( */}
                <LoadingButton
                  type="submit"
                  loading={loading}
                  data-testid="LoadingButton-33818c15-319a-4bd1-8f60-b79eba338845"
                >
                  {dictionary.save}
                </LoadingButton>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={5}
            sx={{
              alignSelf: 'stretch',
              borderRight: t => `1px solid ${t.palette.other.stroke}`,
              borderLeft: t => `1px solid ${t.palette.other.stroke}`
            }}
          >
            <Grid container spacing={3} p={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  data-testid="Typography-6c1d5ff3-88a7-4ffb-afb5-4039ca4a23e4"
                >
                  {dictionary.preview_email}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.light"
                  data-testid="Typography-96782c7f-e4f3-49a4-8fd4-deb6ad96ba6f"
                >
                  {dictionary.preview_email_description}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ backgroundColor: 'secondary.xtraLight' }} p={2}>
                  <Typography
                    variant="body2"
                    color="secondary"
                    data-testid="Typography-96579782-23ba-4413-b5d3-b443fd2715e9"
                  >
                    <b>{`${dictionary.to}: `}</b>
                    {getValues('invoice_email_to')}&nbsp;&nbsp;
                    <b>{'CC: '}</b>
                    {getValues('invoice_email_cc')}&nbsp;&nbsp;
                    <b>{'BCC: '}</b>
                    {getValues('invoice_email_bcc')}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="secondary"
                    pt={2}
                    data-testid="Typography-f6217d65-3523-48ee-a696-86504ae0cec5"
                  >
                    <b>{`${dictionary.subject}: `}</b>
                    {emailSubject}
                  </Typography>
                  {invoiceData?.pdfs?.length > 0 && (
                    <Typography
                      variant="body2"
                      color="secondary"
                      pt={2}
                      data-testid="Typography-3c0d5f4d-3fcf-45fd-8364-7677e914095c"
                    >
                      <b>{`${dictionary.attachment}: `}</b>
                      <a
                        href={`${process.env.NEXT_PUBLIC_API_URL_SHORT}${invoiceData?.pdfs[0].file}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconButton
                          size="small"
                          data-testid="IconButton-740133d7-e211-42c2-9515-169ff45e79dc"
                        >
                          <OpenInNew color="secondary" fontSize="small" />
                        </IconButton>
                      </a>
                    </Typography>
                  )}

                  <Typography
                    variant="body2"
                    color="secondary"
                    pt={2}
                    data-testid="Typography-b65907c2-8f0e-4fcf-a773-8b2d9c7d17e0"
                  >
                    <b>{`${dictionary.body}: `}</b>
                    <span
                      dangerouslySetInnerHTML={{ __html: emailBody }}
                      data-testid="span-5a5c26ba-f068-4074-80a6-64b3775613b9"
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {/*  */}
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent={'flex-end'}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<SendIcon />}
                      onClick={handleSendTest}
                      data-testid="Button-788b8e68-0acb-40f8-801a-1a0304fbb65d"
                    >
                      {dictionary.send_test}
                    </Button>
                  </Grid>
                  {!invoiceData?.is_sent && (
                    <Grid item>
                      {!invoiceData?.is_scheduled && (
                        <Grid item>
                          <Tooltip
                            title={
                              canSend ? null : dictionary.to_schedule_tooltip
                            }
                          >
                            <div>
                              <Button
                                variant="outlined"
                                startIcon={<ScheduleSendIcon />}
                                onClick={() => setIsschedule(true)}
                                disabled={!canSend}
                                data-testid="Button-86869108-88ed-4e32-b967-3463c45090af"
                              >
                                {dictionary.to_schedule}
                              </Button>
                            </div>
                          </Tooltip>
                        </Grid>
                      )}

                      {invoiceData?.is_scheduled && (
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<ScheduleSendIcon />}
                          onClick={() => setIsCancelScheduling(true)}
                          disabled={!canSend}
                          data-testid="Button-ba166187-ca0b-48a4-9ce1-b030d1933e34"
                        >
                          {dictionary.Invoicing.cancel_scheduling}
                        </Button>
                      )}
                    </Grid>
                  )}

                  {!invoiceData?.is_sent && (
                    <Grid item>
                      <Tooltip
                        title={canSend ? null : dictionary.to_schedule_tooltip}
                      >
                        <div>
                          <Button
                            startIcon={<SendIcon />}
                            onClick={() => setIsConfirmSend(true)}
                            disabled={!canSend}
                            data-testid="Button-a07f18a4-9fe2-4357-a38f-5f9e725190ac"
                          >
                            {dictionary.send_now}
                          </Button>
                        </div>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                {/*  */}
                {/* {!canSend && (
                          <Grid
                            container
                            spacing={2}
                            display="flex"
                            justifyContent={'flex-end'}
                          >
                            <Grid item>
                              <Tooltip title={dictionary.cannot_send_email_description}>
                                <span>
                                  <Button
                                    // disabled
                                    variant="outlined"
                                    startIcon={<SendIcon />}
                                  >
                                    {dictionary.send_test}
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title={dictionary.cannot_send_email_description}>
                                <span>
                                  <Button
                                    // disabled
                                    variant="outlined"
                                    startIcon={<ScheduleSendIcon />}
                                  >
                                    {dictionary.to_schedule}
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title={dictionary.cannot_send_email_description}>
                                <span>
                                  <Button startIcon={<SendIcon />}>
                                    {dictionary.send_now}
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                         )} */}
                {/* {canSend && (
                          <Grid
                            container
                            spacing={2}
                            display="flex"
                            justifyContent={'flex-end'}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                startIcon={<SendIcon />}
                                onClick={handleSendTest}
                              >
                                {dictionary.send_test}
                              </Button>
                            </Grid>
                            {!invoiceData?.is_sent && (
                              <Grid item>
                                {!invoiceData?.is_scheduled && (
                                  <Button
                                    variant="outlined"
                                    startIcon={<ScheduleSendIcon />}
                                    onClick={() => setIsschedule(true)}
                                  >
                                    {dictionary.to_schedule}
                                  </Button>
                                )}
                                {invoiceData?.is_scheduled && (
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<ScheduleSendIcon />}
                                    onClick={() => setIsCancelScheduling(true)}
                                  >
                                    {dictionary.Invoicing.cancel_scheduling}
                                  </Button>
                                )}
                              </Grid>
                            )}
                            {!invoiceData?.is_sent && (
                              <Grid item>
                                <Button
                                  startIcon={<SendIcon />}
                                  onClick={() => setIsConfirmSend(true)}
                                >
                                  {dictionary.send_now}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                         )} */}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={3}
            spacing={3}
            p={3}
            sx={{ height: 'fit-content' }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="text.secondary"
                data-testid="Typography-0a267a54-e0b5-49e5-a404-8b07e99294e5"
              >
                {dictionary.error_email_activity}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer className={classesTable.container}>
                <Table>
                  <TableBody>
                    {!invoiceData?.email_logs?.length && (
                      <TableRow className={classesTable.row}>
                        <TableCell align="center">
                          {dictionary.no_email_activity}
                        </TableCell>
                      </TableRow>
                    )}

                    {invoiceData?.email_logs?.map((el, i) => (
                      <TableRow key={i} className={classesTable.row}>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Avatar>
                              <Mail fontSize="small" />
                            </Avatar>
                            <Stack>
                              <Typography
                                variant="subtitle2"
                                data-testid="Typography-f734ac8a-bbe4-46b1-b316-509105a85267"
                              >
                                {`${dictionary.to}: ${el.to}`}
                              </Typography>
                              <Typography
                                variant="caption"
                                data-testid="Typography-21a70cbe-b670-4ebe-803a-51215dea3ba5"
                              >
                                {format(
                                  unformatDate(el.date, 'yyyy-MM-dd'),
                                  'PP',
                                  lang
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <CommonModal
        title={dictionary.schedule_email_invoice}
        onClose={() => setIsschedule(false)}
        open={isschedule}
        maxWidth="xs"
      >
        <ScheduleModal
          invoiceData={invoiceData}
          handleClose={() => setIsschedule(false)}
          invoicesURL={invoicesURL}
          fetchInvoiceUrl={fetchInvoiceUrl}
        />
      </CommonModal>

      <DeleteModal
        open={isCancelScheduling}
        onClose={() => setIsCancelScheduling(false)}
        onConfirm={() =>
          cancelScheduling({ is_scheduled: false, send_on_date: null })
        }
        description={`${dictionary.Invoicing.cancel_scheduling}?`}
        loading={loading}
      />

      <DeleteModal
        open={isConfirmSend}
        onClose={() => setIsConfirmSend(false)}
        onConfirm={handleSend}
        description={dictionary.confirm_to_send_email_invoice}
        loading={loading}
        type="confirm"
      />

      <CommonModal
        open={isNotify}
        title={dictionary.notify_co_contractors}
        onClose={() => setIsNotify(false)}
        maxWidth={'lg'}
      >
        <NotifyCoContractors
          invoiceId={invoiceData?.pk}
          contractors={contractors}
          onClose={() => setIsNotify(false)}
          invoiceData={invoiceData}
        />
      </CommonModal>
    </>
  )
}

export default Email
