import client from '@config/client'
import API_URL from '@config/services'
import queryString from '@utils/queryString'

const cacheBuster = url => `${url}?cb=${Date.now()}`

export const createInvoices = (orgPk, teamPk, payload = {}) => {
  return client
    .post(API_URL.INVOICES.POST_INVOICE_USER_LIST_PK(orgPk, teamPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const onFetchInvoices = (params = {}) => {
  return client
    .get(
      Object.keys(params).length > 0
        ? API_URL.INVOICES.GET_INVOICES_LIST_PK() + '?' + queryString(params)
        : API_URL.INVOICES.GET_INVOICES_LIST_PK()
    )
    .then(response => response)
    .catch(error => error.response)
}

export const createInvoice = (payload = {}) => {
  return client
    .post(API_URL.INVOICES.POST_INVOICES(), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const getInvoicePreviewPDF = invoiceId => {
  return client
    .get(cacheBuster(API_URL.INVOICES.GET_INVOICE_PREVIEW(invoiceId)), {
      responseType: 'arraybuffer'
    })
    .then(response => response)
    .catch(error => error.response)
}

export const onDeleteInvoice = invoiceId => {
  return client
    .delete(API_URL.INVOICES.DELETE_INVOICES(invoiceId))
    .then(response => response)
    .catch(error => error.response)
}

export const updateInvoice = (id, payload = {}) => {
  return client
    .patch(API_URL.INVOICES.PATCH_INVOICE_PK(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateInvoiceItem = (invoiceId, id, payload = {}) => {
  return client
    .patch(API_URL.INVOICES.PATCH_INVOICE_ITEM(invoiceId, id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const resetOrders = id => {
  return client
    .post(API_URL.INVOICES.POST_RESET_ORDERS(id))
    .then(response => response)
    .catch(error => error.response)
}

export const postInvoiceContractGenerateItem = id => {
  return client
    .post(API_URL.INVOICES.POST_INVOICES_CONTRACT_ITEM_GENERATE(id))
    .then(response => response)
    .catch(error => error.response)
}

export const postInvoiceSyncContractInvoiceProgress = (id, payload) => {
  return client
    .post(
      API_URL.INVOICES.POST_INVOICES_SYNC_CONTRACT_INVOICE_PROGRESS(id),
      payload
    )
    .then(response => response)
    .catch(error => error.response)
}

export const removeFeeProjectTitle = id => {
  return client
    .post(API_URL.INVOICES.POST_REMOVE_FEE_PROJECT_TITLES(id))
    .then(response => response)
    .catch(error => error.response)
}

export const getInvoiceItemUpdate = id => {
  return client
    .post(API_URL.INVOICES.GET_INVOICE_ITEM_UPDATE(id))
    .then(response => response)
    .catch(error => error.response)
}

export const deleteInvoiceItem = (invoiceId, id) => {
  return client
    .delete(API_URL.INVOICES.DELETE_INVOICE_ITEM_PK(invoiceId, id))
    .then(response => response)
    .catch(error => error.response)
}

export const getContractItem = id => {
  return client
    .get(API_URL.INVOICES.GET_CONTRACT_ITEM(id))
    .then(response => response)
    .catch(error => error.response)
}

export const launchSuggestions = (id, team_id) => {
  return client
    .post(API_URL.INVOICES.POST_GENERATE_AUTO_DRAFT_PK(id, team_id))
    .then(response => response)
    .catch(error => error.response)
}

export const syncClient = id => {
  return client
    .post(API_URL.INVOICES.POST_SYNC_CLIENT(id))
    .then(response => response)
    .catch(error => error.response)
}

/* Followup rules */

export const createInvoicesFollowupRule = (orgPk, payload) => {
  return client
    .post(API_URL.INVOICES.POST_INVOICES_FOLLOWUP_RULES_LIST(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateInvoicesFollowupRule = (id, payload) => {
  return client
    .patch(API_URL.INVOICES.PATCH_INVOICES_FOLLOWUP_RULE(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteInvoicesFollowupRule = id => {
  return client
    .delete(API_URL.INVOICES.DELETE_INVOICES_FOLLOWUP_RULE(id))
    .then(response => response)
    .catch(error => error.response)
}

/* Invoice preview */

export const createInvoicePreview = orgPk => {
  return client
    .post(API_URL.INVOICES.POST_INVOICE_PREVIEW(orgPk))
    .then(response => response)
    .catch(error => error.response)
}

export const extractInvoice = (payload, headers = {}) => {
  return client
    .post(API_URL.OCR.INVOICE(), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

// Mail

export const sendEmailTest = (id, payload, headers = {}) => {
  return client
    .patch(API_URL.INVOICES.PATCH_INVOICE_PK(id), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

/* Actions */
export const postInvoicesAction = (orgPk, teamPk, payload) => {
  return client
    .post(API_URL.INVOICES.POST_INVOICES_ACTION(orgPk, teamPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const postFollowupsAction = (orgPk, teamPk, payload) => {
  return client
    .post(
      API_URL.INVOICES.POST_INVOICES_FOLLOWUP_ACTION(orgPk, teamPk),
      payload
    )
    .then(response => response)
    .catch(error => error.response)
}

/* Items */
export const postInvoicesItem = (invoiceId, payload = {}) => {
  return client
    .post(API_URL.INVOICES.POST_INVOICE_ITEM(invoiceId), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const patchInvoiceContractItem = (id, payload) => {
  return client
    .patch(API_URL.INVOICES.PATCH_CONTRACT_ITEM(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteInvoiceContractItem = id => {
  return client
    .delete(API_URL.INVOICES.DELETE_CONTRACT_ITEM(id))
    .then(response => response)
    .catch(error => error.response)
}

export const getInvoiceGeneratePdf = id => {
  return client
    .get(API_URL.INVOICES.GET_INVOICE_GENERATE_PDF(id))
    .then(response => response)
    .catch(error => error.response)
}

export const onDownloadInvoicePDF = (invoiceId, invoiceName) => {
  const cacheBuster = url => `${url}?cb=${Date.now()}`
  return client
    .get(cacheBuster(`/invoices/preview/${invoiceId}/`), {
      responseType: 'blob'
    })
    .then(response => {
      if (response?.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${invoiceName}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return response
      }
    })
    .catch(error => {
      return error.response
    })
}

/* Followups */
export const patchInvoicesFollowup = (id, payload) => {
  return client
    .patch(API_URL.INVOICES.PATCH_INVOICES_FOLLOWUP(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteInvoicesFollowup = id => {
  return client
    .delete(API_URL.INVOICES.DELETE_INVOICES_FOLLOWUP(id))
    .then(response => response)
    .catch(error => error.response)
}

export const postInvoicesFollowupGenerate = (orgPk, teamPk) => {
  return client
    .post(API_URL.INVOICES.POST_INVOICES_FOLLOWUP_GENERATE(orgPk, teamPk))
    .then(response => response)
    .catch(error => error.response)
}

export const postInvoicesFollowupSend = id => {
  return client
    .post(API_URL.INVOICES.POST_FOLLOWUP_SEND(id))
    .then(response => response)
    .catch(error => error.response)
}

export const postNotifyContractors = (id, payload) => {
  return client
    .post(API_URL.INVOICES.POST_NOTIFY_CONTRACTORS(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const downloadFile = (name, pk, org) => {
  return client
    .get(API_URL.FILES.DOWNLOAD_FILE + '?file_pk=' + pk + '&org=' + org, {
      responseType: 'blob'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(error => error.response)
}

export const onGenerateInvoiceItems = (id, action) => {
  return client
    .post(API_URL.INVOICES.POST_GENERATE_INVOICE_ITEM(id), {
      action: action
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

// Invoice Templates

export const getInvoiceTemplates = () => {
  return client
    .get(API_URL.INVOICES.GET_TEMPLATES())
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const generateSuggestion = payload => {
  return client
    .post(API_URL.INVOICES.GENERATE_SUGGESTION(), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
