import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import Navigation from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs/Navigation'
import Invoice from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs/ContentTabs/Invoice'
import InvoiceReportsPDF from '@components/Invoicing/InvoicesTabs/InvoicesInfo/DetailModal/Tabs/Pdfs/ContentTabs/InvoiceReportsPDF'
// import { postReportPreviewByQuery } from '@api/reports'
// import { useAuth } from '@contexts/auth'
// import queryString from '@utils/queryString'
// import { responseHandler } from '@utils/responseHandler'
// import { useTranslation } from '@contexts/translation'
// import { useSnackbar } from 'notistack'

const Pdfs = ({
  contractorsEnabled,
  invoiceData,
  fetchInvoiceUrl,
  // projectId,
  project,
  preview
}) => {
  // const { organization, organizationTeam } = useAuth()
  // const { dictionary } = useTranslation()
  // const { enqueueSnackbar } = useSnackbar()

  const [view, setView] = useState(0)
  const [previewPDF, setPreviewPDF] = useState(null)
  // const [loading, setLoading] = useState(false)
  // const [properties, setProperties] = useState({
  //   font_size: '14',
  //   orientation: 'portrait'
  // })

  const handleView = id => {
    setView(id)
  }

  // const getData = useCallback(async () => {
  //   setLoading(true)
  //   const response = await postReportPreviewByQuery(
  //     queryString({
  //       org: organization,
  //       invoice: invoiceData?.id,
  //       team: organizationTeam,
  //       type:
  //         view === 1
  //           ? 'invoice_contractor_invocing'
  //           : view === 2
  //           ? 'invoice_repartition_of_payments'
  //           : view === 3
  //           ? 'invoice_co_contract_statuses'
  //           : view === 4
  //           ? 'price_revisions'
  //           : view === 5
  //           ? 'invoice_sub_contract_statuses'
  //           : view === 6
  //           ? 'project_balance'
  //           : '',
  //       project: projectId,
  //       font_size: properties.font_size,
  //       orientation: properties.orientation
  //     })
  //   )

  //   responseHandler({
  //     response,
  //     callback: async () => {
  //       const fileBlob = new Blob([response.data], {
  //         type: 'application/pdf'
  //       })
  //       const fileURL = URL.createObjectURL(fileBlob)
  //       setPreviewPDF(fileURL)
  //     },
  //     callbackError: () => setPreviewPDF('error'),
  //     dictionary,
  //     snackbar: enqueueSnackbar
  //   })

  //   setLoading(false)
  // }, [])

  // useEffect(() => {
  //   if (invoiceData?.id && projectId) {
  //     getData()
  //   }
  // }, [invoiceData?.id, projectId, view, properties, getData])

  // useEffect(() => {
  //   getInvoicePDF && getInvoicePDF()
  // }, [getInvoicePDF])

  useEffect(() => {
    if (preview) {
      setPreviewPDF(preview)
    }
  }, [preview])

  return (
    <Grid container>
      <Grid
        item
        xs={2}
        sx={{ borderRight: t => `1px solid ${t.palette.other.stroke}` }}
      >
        <Navigation
          view={view}
          handleNavigation={handleView}
          project={project}
          invoiceType={invoiceData?.type}
          contractorsEnabled={contractorsEnabled}
          invoiceData={invoiceData}
        />
      </Grid>
      <Grid item xs={10} p={3}>
        {view === 0 && (
          <Invoice
            invoiceData={invoiceData}
            preview={preview}
            fetchInvoiceUrl={fetchInvoiceUrl}
          />
        )}
        {view > 0 && (
          <InvoiceReportsPDF
            previewPDF={previewPDF}
            // loading={loading}
            // setProperties={setProperties}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Pdfs
