import {
  Grid,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  CircularProgress,
  Chip
} from '@mui/material'
import {
  AutoFixHigh as AutoFixHighIcon,
  Info as InfoIcon,
  Notifications,
  Remove
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { mutate } from 'swr'

import { useTranslation } from '@contexts/translation'
import { postInvoiceContractGenerateItem } from '@api/invoices'

import PlaceHolder from '@components/common/PlaceHolder'

import useTableStyles from '@styles/table/table'
import numberWithCommas from '@utils/numberWithCommas'
import DeleteModal from '@components/common/DeleteModal'
import { useState } from 'react'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import getTotal from '@utils/getTotal'
import CommonModal from '@components/common/Modal/CommonModal'
import NotifyCoContractors from './NotifyCoContractors'
import { responseHandler } from '@utils/responseHandler'

const Summary = ({
  handleSelect,
  contractors,
  isLoadingData,
  invoiceId,
  toMutate,
  invoiceData
}) => {
  const classesTable = useTableStyles()

  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [isGenerate, setIsGenerate] = useState(false)
  const [isNotify, setIsNotify] = useState(false)

  const handleGenerateContractorInvoicing = async () => {
    setLoading(true)
    const response = await postInvoiceContractGenerateItem(invoiceId)

    responseHandler({
      response,
      callback: async () => await mutate(toMutate),
      dictionary,
      msgSuccess: dictionary.confirm_generate_action_success,
      snackbar: enqueueSnackbar
    })

    setIsGenerate(false)
    setLoading(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            variant="h6"
            color="text.secondary"
            data-testid="Typography-a5d0606b-d1ad-4372-9e10-e21dc5770c0e"
          >
            {dictionary.contractor_invoicing}
          </Typography>
          <Tooltip
            title={dictionary.contractor_invoicing_title}
            sx={{ cursor: 'pointer' }}
          >
            <InfoIcon />
          </Tooltip>
        </Stack>
      </Grid>

      <Grid item xs={12} textAlign="right">
        <FilterActionsSelector
          isMenu
          options={[
            {
              label: dictionary.actions,
              handleClick: () => null
            },
            {
              label: dictionary.generate_contractor_invoicing,
              icon: <AutoFixHighIcon color="secondary" />,
              handleClick: () => setIsGenerate(true)
            },
            {
              label: dictionary.prepare_email_cocontractors_invoicing,
              icon: <Notifications color="secondary" />,
              handleClick: () => setIsNotify(true)
            }
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classesTable.headerCell}>
                  {dictionary.company}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.fees}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.previously_invoiced}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.subtotal}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingData && (
                <TableRow className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}

              {!isLoadingData && !contractors?.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <PlaceHolder />
                  </TableCell>
                </TableRow>
              )}

              {!isLoadingData &&
                contractors?.map(item => (
                  <TableRow key={item.id} className={classesTable.row}>
                    <TableCell>
                      <Stack onClick={() => handleSelect(item.id)}>
                        <Typography
                          variant="linkOption"
                          data-testid="Typography-9b4dbb18-f730-4586-a4f4-13d3fed51fc7"
                        >
                          {item.name || <Remove fontSize="small" />}
                          <Chip
                            size="small"
                            sx={{ ml: 1 }}
                            label={
                              item.type === 'co'
                                ? dictionary.co_contractor
                                : dictionary.sub_contractor
                            }
                            data-testid="Chip-4e22ba6e-6371-4c42-9b03-48b472efbdd6"
                          />
                        </Typography>
                        <Typography
                          variant="caption"
                          data-testid="Typography-61b9566e-beba-4da2-970e-f377cf7850f7"
                        >
                          {item.email ? (
                            item.email
                          ) : (
                            <Remove fontSize="small" />
                          )}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(item.invoice?.total_fees || 0, 2)}
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(
                        item?.invoice?.past_billed_amount || 0,
                        2
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(item?.invoice?.amount || 0, 2)}
                    </TableCell>
                  </TableRow>
                ))}

              {!isLoadingData && contractors?.length > 0 && (
                <TableRow hover={false} className={classesTable.rowTotal}>
                  <TableCell colSpan="2" align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-f198a4f2-f1af-408b-950e-272eee951716"
                    >
                      {numberWithCommas(
                        getTotal(
                          contractors.map(c => c.invoice),
                          'total_fees'
                        ) || 0,
                        2
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-a540d655-6826-4ec7-ba61-20f5cb274dbe"
                    >
                      {numberWithCommas(
                        getTotal(
                          contractors.map(c => c.invoice),
                          'past_billed_amount'
                        ) || 0,
                        2
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-5281aa2f-c73c-4e28-a486-05a514ae9dc3"
                    >
                      {numberWithCommas(
                        getTotal(
                          contractors.map(c => c.invoice),
                          'amount'
                        ) || 0,
                        2
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <DeleteModal
        open={isGenerate}
        onClose={() => setIsGenerate(false)}
        onConfirm={handleGenerateContractorInvoicing}
        description={dictionary.confirm_to_generate_contract_invoice_items}
        loading={loading}
        type="confirm"
      />

      <CommonModal
        open={isNotify}
        title={dictionary.notify_co_contractors}
        onClose={() => setIsNotify(false)}
        maxWidth={'lg'}
      >
        <NotifyCoContractors
          invoiceId={invoiceId}
          contractors={contractors}
          onClose={() => setIsNotify(false)}
          invoiceData={invoiceData}
        />
      </CommonModal>
    </Grid>
  )
}

export default Summary
