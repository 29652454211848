import React from 'react'
import {
  List,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography
} from '@mui/material'
import {
  Mail,
  Groups,
  LocalAtm,
  FormatListBulleted,
  ShowChart,
  AccountBalance
} from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'
import API_URL from '@config/services'
import queryString from '@utils/queryString'
import { useFetchData } from '@api/fetchData'

const NavItems = ({ isSelected, item, handleNavigation }) => {
  return (
    <ListItemButton
      key={item.id}
      onClick={() => handleNavigation(item.id)}
      selected={isSelected}
      disabled={item.disabled || false}
      sx={{
        pl: 0,
        py: '8px',
        '&.Mui-selected': {
          '&:hover': {
            background: theme => theme.palette.secondary.light
          },
          background: theme => theme.palette.secondary.light,

          boxShadow: theme =>
            `inset -2px 0px 0px ${theme.palette.secondary.main}`
        }
      }}
      data-testid="ListItemButton-db373a62-70dd-4a92-a361-b713aa376400"
    >
      <ListItemIcon
        sx={{
          minWidth: 'unset',
          pl: '28px',
          mr: '12px',
          color: isSelected ? 'secondary.main' : 'text.primary'
        }}
      >
        {item.icon}
      </ListItemIcon>

      <Typography
        variant={isSelected ? 'subtitle2' : 'body2'}
        color={isSelected ? 'secondary.main' : 'text.primary'}
        data-testid="Typography-b49db37a-6c6f-4f31-ab1a-6de8cb548a87"
      >
        {item.title}
      </Typography>
    </ListItemButton>
  )
}

const Navigation = ({
  view,
  handleNavigation,
  project = null,
  invoiceType,
  contractorsEnabled,
  invoiceData
}) => {
  const { dictionary } = useTranslation()

  const contractorsURL = invoiceData?.project
    ? API_URL.PROJECT.GET_DELIVERABLES_CONTRACTORS(invoiceData?.project) +
      '?' +
      queryString({ invoice: invoiceData?.pk })
    : null
  const { data: contractors } = useFetchData(contractorsURL)

  const subContractorsData = contractors
    ? contractors?.filter(c => !c.is_internal && c.type === 'direct-sub')
    : []

  let MENU_ITEMS = [
    {
      id: 0,
      title: dictionary.invoice,
      icon: <Mail />,
      disabled: false
    }
  ]

  const MENU_ITEMS_CONTRACTOR = [
    {
      id: 1,
      title: project?.multi_payers_enabled ? (
        <Tooltip title={dictionary.not_available_with_multi_payer}>
          <span data-testid="span-b2c53581-9266-4195-8021-c8946390ccb9">
            {dictionary.global_fees}
          </span>
        </Tooltip>
      ) : (
        dictionary.global_fees
      ),

      icon: <Groups />,
      disabled: project?.multi_payers_enabled
    },
    {
      id: 2,
      title: dictionary.repartition_of_payments,
      icon: <LocalAtm />,
      disabled: false
    },
    {
      id: 3,
      title: dictionary.cocontractors_details,
      icon: <FormatListBulleted />,
      disabled: false
    }
  ]

  const MENU_ITEMS_PRICE = [
    {
      id: 4,
      title: dictionary.price_revisions,
      icon: <ShowChart />,
      disabled: false
    }
  ]

  const SUBCONTRACTORS_ATTESTATION = [
    {
      id: 5,
      title: dictionary.subcontractor_attestations,
      icon: <FormatListBulleted />,
      disabled: false
    }
  ]

  const ACCOUNT_STATEMENT = [
    {
      id: 6,
      title: dictionary.account_statement,
      icon: <AccountBalance />,
      disabled: false
    }
  ]

  if (invoiceType === 1 && contractorsEnabled) {
    if (project?.contractor_invoicing_enabled) {
      MENU_ITEMS = MENU_ITEMS.concat(MENU_ITEMS_CONTRACTOR)
    }
    if (subContractorsData && subContractorsData.length > 0)
      MENU_ITEMS = MENU_ITEMS.concat(SUBCONTRACTORS_ATTESTATION)
  }
  if (project && !project.multi_payers_enabled)
    MENU_ITEMS = MENU_ITEMS.concat(ACCOUNT_STATEMENT)
  if (invoiceType === 1 && project?.public_indices_enabled) {
    MENU_ITEMS = MENU_ITEMS.concat(MENU_ITEMS_PRICE)
  }
  return (
    <List>
      {MENU_ITEMS.map(item => {
        const isSelected = item.id === view
        return item.id !== 1 ||
          (item.id === 1 && !project?.multi_payers_enabled) ? (
          <NavItems
            key={item.id}
            isSelected={isSelected}
            item={item}
            handleNavigation={handleNavigation}
          />
        ) : (
          <Tooltip
            title={dictionary.not_available_with_multi_payer}
            key={item.id}
          >
            <span data-testid="span-fb774851-0323-45fe-bd09-35df186b1936">
              <NavItems
                isSelected={isSelected}
                item={item}
                handleNavigation={handleNavigation}
              />
            </span>
          </Tooltip>
        )
      })}
    </List>
  )
}

export default Navigation
