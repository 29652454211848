import { Badge, Tabs, Tab } from '@mui/material'
import { useTheme } from '@mui/styles'
import useButtonTabsStyles from '@styles/buttonTabs/buttonTabs'
import clsx from 'clsx'
import Link from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'

const VARIANTS = {
  filled: 'filled',
  standard: 'standard'
}

const LinkComponent = React.forwardRef((props, ref) => {
  const { href } = props
  return (
    <Link href={href} passHref ref={ref}>
      <a {...props} />
    </Link>
  )
})

const ButtonTabs = ({
  tabs = [],
  variant = VARIANTS.standard,
  width = 'fit-content'
}) => {
  const classesButtonTabs = useButtonTabsStyles()
  const theme = useTheme()

  const defaultIndex = tabs.findIndex(tab => tab.selected) ?? 0

  const [value, setValue] = useState(defaultIndex)

  useEffect(() => {
    setValue(defaultIndex)
  }, [defaultIndex])

  const classnameSelected = useMemo(
    () =>
      variant === VARIANTS.filled
        ? classesButtonTabs.detailsSelected
        : classesButtonTabs.detailsSelectedStandard,
    [variant]
  )

  // tabs = {
  //   isVisible: boolean,
  //   link: string | undefined,
  //   badgeCount: number,
  //   startIcon: React node,
  //   onClick: function,
  //   selected: boolean,
  //   text: string
  // }[]

  return (
    <Tabs
      variant="standard"
      value={variant === VARIANTS.standard ? value : false}
      sx={{
        borderBottom: t => `1px solid ${t.palette.other.stroke}`,
        width: width
      }}
    >
      {tabs.map((tab, i) => {
        const Icon = tab.startIcon

        return (
          (tab.isVisible || tab.isVisible === undefined) &&
          (tab.link ? (
            <Tab
              key={tab.id || i}
              href={tab.link}
              className={clsx(classesButtonTabs.detailsRoot, {
                [classnameSelected]: tab.selected || false
              })}
              LinkComponent={LinkComponent}
              disableRipple={variant === VARIANTS.standard}
              label={
                <div style={{ display: 'flex', gap: 5, padding: 2 }}>
                  {tab.startIcon && <Icon sx={{ fontSize: 15 }} />}
                  {tab.badgeCount ? (
                    <Badge
                      badgeContent={tab.badgeCount ?? 0}
                      color="primary"
                      sx={{
                        fontSize: 12,
                        minWidth: 0,
                        marginRight: 5,
                        '& .MuiBadge-badge': {
                          fontSize: 12,
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.primary.main,
                          fontWeight: 800,
                          borderRadius: '8px',
                          right: '-20px',
                          top: '8px'
                        }
                      }}
                    >
                      {tab.text}
                    </Badge>
                  ) : (
                    tab.text
                  )}
                </div>
              }
            />
          ) : (
            <Tab
              key={tab.id || i}
              className={clsx(classesButtonTabs.detailsRoot, {
                [classnameSelected]: tab.selected || false
              })}
              value={i}
              disableRipple={variant === VARIANTS.standard}
              onClick={() => {
                tab.onClick()
                setValue(i)
              }}
              label={
                <div style={{ display: 'flex', gap: 5, padding: 2 }}>
                  {tab.startIcon && <Icon sx={{ fontSize: 15 }} />}
                  {tab.badgeCount ? (
                    <Badge
                      badgeContent={tab.badgeCount ?? 0}
                      color="primary"
                      sx={{
                        fontSize: 12,
                        minWidth: 0,
                        marginRight: 5,
                        '& .MuiBadge-badge': {
                          fontSize: 12,
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.primary.main,
                          fontWeight: 800,
                          borderRadius: '8px',
                          right: '-20px',
                          top: '8px'
                        }
                      }}
                    >
                      {tab.text}
                    </Badge>
                  ) : (
                    tab.text
                  )}
                </div>
              }
            />
          ))
        )
      })}
    </Tabs>
  )
}

export default ButtonTabs
