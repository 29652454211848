import React, { useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Grid, MenuItem, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import DatePicker from '@components/form/DatePicker'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import { onCreatePayment } from '@api/payments'

export default function PaymentForm(props) {
  const { handleClose, mutateURL, operationId, invoiceData } = props
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    amount: yup.string().required(dictionary.required),
    date: yup.string().required(dictionary.required),
    description: yup.string(),
    type: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      amount: invoiceData?.amount ?? '0',
      date: new Date()
    }
  })

  const onCreate = async values => {
    const payload = {
      amount: values.amount,
      date: format(new Date(values.date), 'dd-MM-yyyy'),
      description: values.description,
      type: values.type || null
    }
    if (operationId || invoiceData.id)
      payload['operation'] = Number(operationId)
    if (invoiceData.id) payload['tenant'] = invoiceData.tenant?.id

    const response = await onCreatePayment(invoiceData.id, payload)

    responseHandler({
      response,
      callback: async () => {
        for (let url of mutateURL) await mutate(url)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.payment_created,
      snackbar: enqueueSnackbar
    })
  }

  const onSubmit = async values => {
    setLoading(true)
    onCreate(values)
    setLoading(false)
    handleClose && handleClose()
  }

  const types = [
    { value: 'cash', display: dictionary['cash'] },
    { value: 'check', display: dictionary['check'] },
    { value: 'card', display: dictionary['card'] },
    { value: 'lcr', display: dictionary['lcr'] },
    { value: 'transfer', display: dictionary['transfer'] },
    { value: 'deposit', display: dictionary['deposit'] },
    { value: 'rate', display: dictionary['rate'] },
    { value: '-', display: dictionary['none'] }
  ]

  return (
    <Grid item xs={12}>
      <form
        data-cy={'form'}
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="amount"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  type="number"
                  label={dictionary.amount}
                  error={Boolean(errors.amount)}
                  helperText={errors.amount && errors.amount.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              required
              name="date"
              label={dictionary.date}
              variant="outlined"
              fullWidth
              control={control}
              error={errors.date}
              helperText={errors.date && errors.date.message}
              data_testid="DatePicker-15a940f3-53e9-42c4-bea1-a59d8fc17cd0"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  label={dictionary.description}
                  error={Boolean(errors.description)}
                  helperText={errors.description && errors.description.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="type"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  variant="filled"
                  label={dictionary.type}
                  error={Boolean(errors.type)}
                  helperText={errors.type && errors.type.message}
                >
                  {types.map(({ value, display }, idx) => (
                    <MenuItem
                      key={idx}
                      value={value}
                      data-testid="MenuItem-abb6fd08-2181-413a-8115-72a33d7b1fa5"
                    >
                      {display}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              type="submit"
              disabled={loading}
              data-testid="Button-caabfa12-35a1-4ab3-a505-630e5bc3efda"
            >
              {dictionary.save}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
