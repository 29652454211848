import React, { useEffect, useState } from 'react'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { responseHandler } from '@utils/responseHandler'
import useTableStyles from '@styles/table/table'
import { useSnackbar } from 'notistack'

import {
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TableContainer,
  Typography,
  Stack,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  MenuItem,
  Paper,
  Menu
} from '@mui/material'

import PlaceHolder from '@components/common/PlaceHolder'
import ActionsPopover from '@components/common/ActionsPopover'
import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'

import { Update, Delete, Refresh, Add, ArrowBackIos } from '@mui/icons-material'
import {
  DraggableComponent,
  DroppableComponent,
  onDragEnd
} from '@utils/dragAndDrop'

import {
  updateInvoiceItem,
  getInvoiceItemUpdate,
  deleteInvoiceItem,
  postInvoicesItem,
  onGenerateInvoiceItems
} from '@api/invoices'
import { mutate } from 'swr'
import numberWithCommas from '@utils/numberWithCommas'
import DeleteModal from '@components/common/DeleteModal'
import roundNumber from '@utils/roundNumber'

export const ElementsTableTotal = ({ invoiceData }) => {
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()

  return (
    <TableContainer
      component={({ children }) => (
        <Paper className={classesTable.containerTotal}>{children}</Paper>
      )}
    >
      <Table>
        <TableBody>
          <TableRow hover={false} className={classesTable.rowTotal}>
            <TableCell>
              <Typography
                variant="subtitle2"
                color="secondary"
                data-testid="Typography-85085b51-2a8d-438c-8c6d-10834085dd09"
              >
                {dictionary.subtotal}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                variant="subtitle2"
                color="secondary"
                data-testid="Typography-3723fd13-cd49-4441-a468-1c8ab6992777"
              >
                {numberWithCommas(
                  invoiceData?.subtotal,
                  2,
                  invoiceData?.currency?.symbol
                )}
              </Typography>
            </TableCell>
          </TableRow>

          {invoiceData?.tax_rates &&
            invoiceData.tax_rates.map(rate => (
              <TableRow
                hover={false}
                className={classesTable.rowTotal}
                key={rate.tax_rate}
              >
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-a7a339e8-93de-483b-8693-ab6d4d074859"
                  >
                    {dictionary.tax} ({roundNumber(rate.tax_rate)}%)
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    data-testid="Typography-3ebe5df9-b010-47ae-9b53-f9bca3cd2c0c"
                  >
                    {numberWithCommas(
                      rate.tax,
                      2,
                      invoiceData?.currency?.symbol
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}

          <TableRow hover={false} className={classesTable.rowTotalDark}>
            <TableCell>
              <Typography
                variant="subtitle2"
                color="secondary.contrastText"
                data-testid="Typography-1de01ad8-d4c6-4701-8f21-c49a4f7ff217"
              >
                {dictionary.total.toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                variant="subtitle2"
                color="secondary.contrastText"
                data-testid="Typography-145adcfc-4dc0-4a51-afa6-60ffba3b2455"
              >
                {numberWithCommas(
                  invoiceData?.amount,
                  2,
                  invoiceData?.currency?.symbol
                )}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default function ElementsTable(props) {
  const {
    invoiceData,
    isInvoiceHistory,
    setIsInvoiceHistory,
    isSent,
    setPreviewPDF,
    fetchInvoiceUrl,
    getInvoicePDF,
    canChange
  } = props
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [dragList, setDragList] = useState([])
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null })
  const [deleting, setDeleting] = useState(false)
  const [anchorAddElement, setAnchorAddElement] = useState()
  const [anchorGenerateItem, setAnchorGenerateItem] = useState()
  const [currentAction, setCurrentAction] = useState(null)
  const [invoiceTotal, setInvoiceTotal] = useState(null)
  const [generating, setGenerating] = useState(false)

  const [infoModal, setInfoModal] = useState({
    open: false,
    msg: '',
    onConfirm: () => {}
  })
  const [loading, setLoading] = useState(false)

  const refreshButton = !isSent

  const fetchInvoiceItemsByIdURL = invoiceData
    ? API_URL.INVOICES.GET_INVOICE_ITEMS_PK(invoiceData?.id)
    : null
  const { data: elements, isLoading: isLoadingData } = useFetchData(
    fetchInvoiceItemsByIdURL
  )

  const currencyInfo = {
    currency_display: invoiceData?.currency?.name,
    currency_symbol: invoiceData?.currency?.symbol || ''
  }

  const invoiceItemTypes = [
    { title: dictionary.Simple_Item, value: 'free' },
    { title: dictionary.rent, value: 'rent' },
    { title: dictionary.Charge, value: 'charges' },
    { title: dictionary.Management_Fees, value: 'mgmt_fees' },
    { title: dictionary.taxes, value: 'taxes' },
    { title: dictionary.Rent_Discount, value: 'rent_discount' },
    { title: dictionary.Charges_Adjustments, value: 'charges_adjustment' },
    { title: dictionary.deposit, value: 'deposit' },
    { title: dictionary.Electricity, value: 'electric' },
    { title: dictionary.Deposit_Adjustment, value: 'deposit_adjustment' }
  ]

  const ELEMENT_ACTIONS = id => [
    {
      id: 0,
      label: dictionary.update,
      icon: <Update color="secondary" />,
      handleClick: async () => {
        const response = await getInvoiceItemUpdate(id)
        responseHandler({
          response,
          dictionary,
          snackbar: enqueueSnackbar
        })
      }
    },
    {
      id: 1,
      label: dictionary.delete,
      icon: <Delete color="error" />,
      handleClick: () => setDeleteModal({ open: true, id })
    }
  ]

  useEffect(() => {
    if (elements) {
      const list = elements.results
      const total = {
        sub_total: list?.reduce((acc, obj) => {
          return acc + obj.subtotal
        }, 0),
        total_amount: list?.reduce((acc, obj) => {
          return acc + obj.amount
        }, 0)
      }

      setDragList(list)
      setInvoiceTotal(total)
    }
  }, [elements])

  const updateAllItems = () => {
    const onOk = async () => {
      setLoading(true)
      const ids = elements?.results.map(el => el.id)
      for (const id of ids) await getInvoiceItemUpdate(id)
      await mutate(fetchInvoiceItemsByIdURL)
      setLoading(false)
      setInfoModal({ open: false, msg: '', onConfirm: () => {} })
    }
    setInfoModal({
      open: true,
      msg: dictionary.Invoicing.update_all_items_action,
      onConfirm: onOk
    })
  }

  const createItem = async (payload = {}) => {
    const response = await postInvoicesItem(invoiceData?.id, payload)

    responseHandler({
      response,
      callback: async () => {
        setPreviewPDF(null)
        await mutate(fetchInvoiceItemsByIdURL)
        await mutate(fetchInvoiceUrl)
        getInvoicePDF()
      },
      dictionary,
      msgSuccess: dictionary.element_created,
      snackbar: enqueueSnackbar
    })
  }

  const handleUpdate = async (id, payload) => {
    const response = await updateInvoiceItem(invoiceData.id, id, payload)

    responseHandler({
      response,
      callback: async () => {
        setPreviewPDF(null)
        await mutate(fetchInvoiceItemsByIdURL)
        await mutate(fetchInvoiceUrl)
        getInvoicePDF()
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })
  }

  const onConfirmDelete = async () => {
    setDeleting(true)
    const response = await deleteInvoiceItem(invoiceData.id, deleteModal.id)

    responseHandler({
      response,
      callback: async () => {
        mutate(fetchInvoiceItemsByIdURL)
        mutate(fetchInvoiceUrl)
        getInvoicePDF()
      },
      dictionary,
      msgSuccess: dictionary.element_deleted,
      snackbar: enqueueSnackbar
    })

    setDeleteModal({ open: false, id: null })
    setDeleting(false)
  }

  const handleOpenGenerateAction = action => {
    setCurrentAction(action)
    setAnchorGenerateItem(null)
  }

  const ADD_ACTIONS = invoiceData.landlord
    ? [
        {
          id: 'generate',
          name: dictionary['generate_landlord_item'],
          onClick: () => {
            createItem({ type: 'free' })
            setAnchorAddElement(null)
          }
        }
      ]
    : [
        {
          id: 'generate',
          name: dictionary['generate_items'],
          onClick: event => {
            setAnchorGenerateItem(event.currentTarget)
            setAnchorAddElement(null)
          }
        },
        {
          id: 'add',
          name: dictionary['add_invoice_item'],
          onClick: () => {
            createItem({ type: 'free' })
            setAnchorAddElement(null)
          }
        }
      ]

  const GENERATE_ACTIONS = [
    {
      id: 'generate_rent_charges',
      name: dictionary['generate_rent_charges'],
      onClick: () => handleOpenGenerateAction('generate_trimester_items')
    },
    {
      id: 'generate_tax_items',
      name: dictionary['generate_tax'],
      onClick: () => handleOpenGenerateAction('generate_tax_items')
    },
    {
      id: 'generate_expense',
      name: dictionary['generate_expense'],
      onClick: () => handleOpenGenerateAction('generate_reconciliation')
    }
  ]

  const onGenerateItems = async () => {
    setGenerating(true)
    const response = await onGenerateInvoiceItems(
      invoiceData?.id,
      currentAction
    )

    if (response) {
      if (response.status === 200) {
        mutate(fetchInvoiceUrl)
        mutate(fetchInvoiceItemsByIdURL)
        enqueueSnackbar(dictionary['invoice_item_generated'], {
          variant: 'success'
        })

        setAnchorAddElement(null)
        setCurrentAction(null)
      }
    }
    setGenerating(false)
  }

  return (
    <>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="h6"
          color="text.secondary"
          data-testid="Typography-7ce4a1e8-4b56-4999-9bc4-f587f4ed6a17"
        >
          {dictionary.elements}
          {isInvoiceHistory
            ? ` - ${dictionary.related_invoice}: ${invoiceData?.code}`
            : ''}
        </Typography>
        <Stack direction="row" spacing={1}>
          {refreshButton && (
            <Tooltip title={dictionary.update_all_items}>
              <IconButton
                size="small"
                onClick={updateAllItems}
                data-testid="IconButton-41826057-85d7-488f-80c7-2e974e0aa8ce"
              >
                <Refresh color="secondary" />
              </IconButton>
            </Tooltip>
          )}

          {!isSent && (
            <>
              <Menu
                id="generate-actions"
                anchorEl={anchorGenerateItem}
                keepMounted
                open={Boolean(anchorGenerateItem)}
                onClose={() => setAnchorGenerateItem(null)}
                anchorOrigin={{
                  vertical: 'top'
                }}
              >
                {GENERATE_ACTIONS.map(action => (
                  <MenuItem
                    key={action.id}
                    value={action.id}
                    onClick={action?.onClick}
                    data-testid="MenuItem-2758aa4d-0037-4918-823d-cee93ffafacb"
                  >
                    {action.name}
                  </MenuItem>
                ))}
              </Menu>
              <Menu
                id="invoice-items-actions"
                anchorEl={anchorAddElement}
                keepMounted
                open={Boolean(anchorAddElement)}
                onClose={() => setAnchorAddElement(null)}
              >
                {ADD_ACTIONS.map(action => (
                  <MenuItem
                    key={action.id}
                    value={action.id}
                    onClick={action?.onClick}
                    data-testid="MenuItem-9760fb5f-ad3a-4a93-8245-ec52f8a65168"
                  >
                    {action.name}
                  </MenuItem>
                ))}
              </Menu>
              <Button
                startIcon={<Add />}
                data-cy={'add-btn'}
                onClick={event => {
                  setAnchorAddElement(event.currentTarget)
                }}
                data-testid="Button-5aa4a2af-323f-4a0f-9293-032182f789aa"
              >
                {dictionary.element}
              </Button>
            </>
          )}

          {isInvoiceHistory && (
            <Button
              startIcon={<ArrowBackIos />}
              variant="outlined"
              onClick={() =>
                setIsInvoiceHistory({ open: false, invoicePk: null })
              }
              data-testid="Button-51e562f2-2517-4485-b414-b087b08553e8"
            >
              {dictionary.back}
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          className={classesTable.container}
          sx={{ maxHeight: '42vh' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {canChange && <TableCell />}
                <TableCell className={classesTable.headerCell}>
                  {dictionary.description}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.type}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.tax_rate}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.subtotal}
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  {dictionary.amount}
                </TableCell>
                {canChange && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody
              component={DroppableComponent(res =>
                onDragEnd(res, dragList, setDragList, handleUpdate)
              )}
            >
              {isLoadingData && (
                <TableRow className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}

              {!isLoadingData && elements && !elements.results.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell colSpan={'100%'}>
                    <PlaceHolder
                      src="/Placeholders/Keysy/Invoice.png"
                      width={190}
                      height={190}
                    />
                  </TableCell>
                </TableRow>
              )}

              {!isLoadingData &&
                dragList &&
                dragList.map((element, index) => (
                  <TableRow
                    key={element.id}
                    className={classesTable.row}
                    component={
                      !canChange
                        ? ({ children }) => (
                            <TableRow
                              key={element.id}
                              className={classesTable.row}
                            >
                              {children}
                            </TableRow>
                          )
                        : DraggableComponent(
                            element.id?.toString(),
                            index,
                            classesTable.row,
                            '#fff',
                            !isSent
                          )
                    }
                  >
                    <TableCell>
                      <Stack>
                        <CommonPopoverForm
                          style={{ marginLeft: 0 }}
                          variant="changeOptionDark"
                          // disabled={isSent}
                          disabled={!canChange}
                          buttonLabel={element.title || dictionary.add_title}
                          propertyLabel={dictionary.title}
                          defaultValue={element.title || ''}
                          onSubmit={values =>
                            handleUpdate(element.id, {
                              title: values.textPopover
                            })
                          }
                          data_testid="CommonPopoverForm-00a99d43-23e0-40f3-8c5a-87c9aa309d43"
                        />

                        <CommonPopoverForm
                          isMultiline
                          style={{ marginLeft: 0 }}
                          // disabled={isSent}
                          disabled={!canChange}
                          variant={
                            element.subtitle
                              ? 'changeOptionDark'
                              : 'changeOptionSmallLight'
                          }
                          buttonLabel={
                            element.description || dictionary.add_description
                          }
                          propertyLabel={dictionary.description}
                          defaultValue={element.description || ''}
                          onSubmit={values =>
                            handleUpdate(element.id, {
                              description: values.textPopover
                            })
                          }
                          data_testid="CommonPopoverForm-46bc2a00-8ee2-4666-95e6-b4eb56d7cf55"
                        />
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <CommonPopoverForm
                        type="select"
                        variant="changeOptionDark"
                        // disabled={isSent}
                        disabled={!canChange}
                        buttonLabel={
                          invoiceItemTypes.find(i => i.value === element.type)
                            ?.title || '-----'
                        }
                        propertyLabel={dictionary.type}
                        defaultValue={element.type || ''}
                        onSubmit={values => {
                          handleUpdate(element.id, {
                            type: values.selectPopover
                          })
                        }}
                        data_testid="CommonPopoverForm-0130a970-ed68-453f-a488-ffa213978d2b"
                      >
                        {invoiceItemTypes.map(type => (
                          <MenuItem
                            key={type.value}
                            value={type.value}
                            data-testid="MenuItem-9a89904c-8849-4343-b93f-0ebc00ccd31b"
                          >
                            {type.title}
                          </MenuItem>
                        ))}
                      </CommonPopoverForm>
                    </TableCell>
                    <TableCell
                      className={classesTable.headerCell}
                      align="right"
                    >
                      <CommonPopoverForm
                        disabled={!canChange}
                        variant="changeOptionDark"
                        buttonLabel={
                          element.tax_rate
                            ? `${roundNumber(element.tax_rate)}%`
                            : '---'
                        }
                        propertyLabel={dictionary.tax_rate}
                        type="number"
                        defaultValue={roundNumber(element.tax_rate) || ''}
                        onSubmit={values =>
                          handleUpdate(element.id, {
                            tax_rate: values.textPopover
                          })
                        }
                        data_testid="CommonPopoverForm-0a16164d-43f1-4506-8855-65442294e774"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <CommonPopoverForm
                        type="number"
                        variant={'changeOptionDark'}
                        // disabled={isSent}
                        disabled={!canChange}
                        buttonLabel={numberWithCommas(
                          element.subtotal,
                          2,
                          currencyInfo.currency_symbol
                        )}
                        propertyLabel={dictionary.subtotal}
                        defaultValue={element.subtotal || 0}
                        onSubmit={values =>
                          handleUpdate(element.id, {
                            subtotal: values.textPopover,
                            type: element.type
                          })
                        }
                        data_testid="CommonPopoverForm-e1b543df-903c-4ca2-8c47-e81dec1f398e"
                      />
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(
                        element.amount,
                        2,
                        currencyInfo.currency_symbol
                      )}
                    </TableCell>
                    {canChange && (
                      <TableCell
                        align="right"
                        className={classesTable.rowActions}
                      >
                        <ActionsPopover actions={ELEMENT_ACTIONS(element.id)} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
            {dragList && dragList.length > 0 && (
              <TableFooter className={classesTable.footer}>
                <TableRow hover={false} className={classesTable.rowTotal}>
                  <TableCell colSpan={!canChange ? 3 : 4} />
                  <TableCell align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-0cc37cd2-982d-4cdf-aacb-e4cd6858157f"
                    >
                      {numberWithCommas(
                        invoiceTotal.sub_total,
                        2,
                        currencyInfo.currency_symbol
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      data-testid="Typography-a8509756-931f-4aec-aa47-ea8d4f1c09e6"
                    >
                      {numberWithCommas(invoiceTotal.total_amount, 2)}{' '}
                      {currencyInfo.currency_symbol}
                    </Typography>
                  </TableCell>
                  {canChange && <TableCell />}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid item xs={3}>
          <ElementsTableTotal invoiceData={invoiceData} elements={dragList} />
        </Grid>
      </Grid>
      <DeleteModal
        open={deleteModal.open ?? false}
        onClose={() => setDeleteModal({ open: false, id: null })}
        onConfirm={onConfirmDelete}
        description={dictionary.confirm_delete_invoice_item}
        loading={deleting}
      />

      <DeleteModal
        open={infoModal.open ?? false}
        onClose={() =>
          setInfoModal({ open: false, msg: '', onConfirm: () => {} })
        }
        onConfirm={infoModal.onConfirm}
        description={infoModal.msg}
        loading={loading}
      />

      <DeleteModal
        open={currentAction ?? false}
        onClose={() => {
          setCurrentAction(null)
          setAnchorAddElement(null)
        }}
        description={dictionary['confirm_generate']}
        isLoading={loading}
        isYellow
        onConfirm={onGenerateItems}
        loading={generating}
      />
    </>
  )
}
