import React, { useState } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Tooltip
} from '@mui/material'
import { Delete } from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'

import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import DeleteModal from '@components/common/DeleteModal'
import { onDeletePayment } from '@api/payments'
import { useSnackbar } from 'notistack'
import { mutate } from 'swr'
import { responseHandler } from '@utils/responseHandler'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import CommonModal from '@components/common/Modal/CommonModal'
import PaymentForm from './PaymentForm'

const Payment = ({
  projectId,
  invoiceData,
  fetchURL,
  invoicesURL,
  invoicesCountsURL,
  setIsAddPayment = () => {},
  isAddPayment = false
}) => {
  const classesTable = useTableStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { dictionary } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null
  })

  // const fetchUrl = isInvoice
  //   ? `/payments/invoice-payment/?invoice=${invoiceData.id}&page=${pageCount}`
  //   : tenantId && operationId
  //   ? `/payments/?operation=${operationId}&tenant=${tenantId}&page=${pageCount}`
  //   : isOperation
  //   ? `/payments/?operation=${operationId}&page=${pageCount}${tenantFilter}${dateStartFilter}${dateEndFilter}`
  //   : `/payments/?page=${pageCount}${tenantFilter}${operationFilter}${dateStartFilter}${dateEndFilter}`

  const fetchInvoicePaymentsURL = API_URL.PAYMENTS.GET_INVOICE_PAYMENTS(
    invoiceData.id
  )

  const { data } = useFetchData(fetchInvoicePaymentsURL)

  const handleDelete = async () => {
    setLoading(true)
    const response = await onDeletePayment(deleteModal.id)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchURL)
        await mutate(fetchInvoicePaymentsURL)
        await mutate(invoicesURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.payment_deleted,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setDeleteModal({ open: false, id: null })
  }

  const translateType = type => {
    switch (type) {
      case 'cash':
        return dictionary.cash
      case 'check':
        return dictionary.check
      case 'card':
        return dictionary.card
      case 'LCR':
        return dictionary.LCR
      case 'transfer':
        return dictionary.transfer
      case 'deposit':
        return dictionary.deposit
      case 'rate':
        return dictionary.rate
      case 'none':
        return dictionary.none
      default:
        return null
    }
  }

  return (
    <>
      <Grid container spacing={3} p={3}>
        <Grid item xs={12}>
          <TableContainer className={classesTable.container}>
            <Table cellSpacing="0" border="0">
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.date}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.type}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.amount}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data && !data.results?.length && (
                  <TableRow hover={false} className={classesTable.row}>
                    <TableCell align="center" colSpan={'100%'}>
                      <PlaceHolder />
                    </TableCell>
                  </TableRow>
                )}

                {data &&
                  data?.results?.map(item => (
                    <TableRow
                      key={item.payment.id}
                      className={classesTable.row}
                    >
                      <TableCell>{item.payment.date}</TableCell>
                      <TableCell>{translateType(item.payment.type)}</TableCell>
                      <TableCell align="right">
                        {`${item.currency_display || ''} ${numberWithCommas(
                          item.amount,
                          2
                        )}`}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classesTable.rowActions}
                      >
                        <Tooltip title={dictionary.delete}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setDeleteModal({
                                open: true,
                                id: item.payment.id
                              })
                            }
                            data-testid="IconButton-f8e01f48-0a02-460c-8b94-d0433ab97f65"
                          >
                            <Delete fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <CommonModal
        title={dictionary.add_payment}
        onClose={() => setIsAddPayment(false)}
        open={isAddPayment}
        maxWidth="xs"
      >
        <PaymentForm
          handleClose={() => setIsAddPayment(false)}
          mutateURL={[
            fetchURL,
            invoicesURL,
            invoicesCountsURL,
            fetchInvoicePaymentsURL
          ]}
          operationId={projectId}
          invoiceId={invoiceData?.id}
          invoiceData={invoiceData}
        />
      </CommonModal>
      <DeleteModal
        open={deleteModal.open}
        onClose={() => setDeleteModal({ open: false, id: null })}
        onConfirm={handleDelete}
        description={dictionary.Invoicing.confirm_action_delete_payment}
        loading={loading}
      />
    </>
  )
}

export default Payment
